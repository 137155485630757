<template>
  <div v-if="itemData">
    <b-row>
      <b-col col lg="12">
        <h4> Editar do produto: {{ itemData.codigo  }} </h4>        
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <b-row>
        <!-- Field: nome -->
        <b-col cols="12" md="4">
          <b-form-group label="Nome Abriado" label-for="nome">
            <b-form-input id="nome" v-model="itemData.nome" placeholder="Informe o nome abreviado do produto" />
          </b-form-group>
        </b-col>
        <!-- Field: Unidade Medida -->
        <b-col cols="12" md="2">          
          <b-form-group label="Unidade de medida" label-for="unidade-medida">
            <v-select v-model="itemData.unidade_medida" :options="listaUnidadeMedidas" label="abreviacao"
              :reduce="val => val" :clearable="false" input-id="unidade-medida"
              placeholder="Escolha a unidade de medida">
              <template v-slot:no-options>
                Nenhuma opção encontrada
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- Field: Marca -->
        <b-col cols="12" md="6">
          <b-form-group label="Marca" label-for="marca">
            <v-select v-model="itemData.marca" :options="listaMarcas" label="nome" :reduce="val => val.id"
              :clearable="false" input-id="marca" placeholder="Escolha a marca">
              <template v-slot:no-options>
                Nenhuma opção encontrada
              </template>
            </v-select>
          </b-form-group>
        </b-col>
         <!-- Field: Familia -->
        <b-col cols="12" md="4">
          <b-form-group label="Familia" label-for="familia">
            <v-select v-model="itemData.familia" :options="listaFamilias" label="nome" :reduce="val => val.id"
              :clearable="false" input-id="familia" placeholder="Escolha a familia">
              <template v-slot:no-options>
                Nenhuma opção encontrada
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group label="Quantidade" label-for="quantidade">
            <b-form-input id="quantidade" v-model="itemData.quantidade" type="number" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Descrição" label-for="descricao">
            <b-form-textarea id="descricao" v-model="itemData.descricao" placeholder="Descrição do produto" rows="3" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Observação" label-for="observacao">
            <b-form-textarea id="observacao" v-model="itemData.observacao" placeholder="Observação do produto"
              rows="3" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <hr />
    <div class="d-flex justify-content-between mb-3">
      <h6 class="mr-2">Fotos do produto</h6>
   
      <b-button type="button" variant="primary" class="mb-1 mb-sm-0 mr-0 ml-sm-1" @click="showModal">
        Adicionar Fotos
      </b-button>
      
    </div>
       <p v-if="listaFotos.length === 0" class="py-1">Nenhuma foto registrada</p>
    <b-row>
      <b-col cols="2" v-for="foto in listaFotos" :key="foto.id" class="text-center card mb-1">
     <b-img :src="foto.path" height="150" class="mb-1" fluid lazy alt="Foto do produto" />
       
        <div class="d-flex ">
          <b-button type="button" variant="light" class="mb-1 mb-sm-0 mr-0 ml-sm-1" @click="visualizarFoto(foto.path)">
            <i class="feather icon-search"></i>
          </b-button>
          <b-button type="button" variant="danger" class="mb-1 mb-sm-0 mr-0 ml-sm-1" @click="modalExcluir(foto)">
            <i class="feather icon-trash-2"></i>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- Action Buttons -->
    <b-col class="text-right pr-0">
      <b-button variant="outline-secondary" type="button" :to="{ name: 'produtos' }">
        Voltar
      </b-button>
      <b-button type="button" variant="success" class="mb-1 mb-sm-0 mr-0 ml-sm-1" @click="editarItem(itemData)">
        Salvar
      </b-button>
    </b-col>
    <!-- Modal  Excluir Foto -->
    <b-modal id="excluir-foto-modal" ref="excluirFotoModal" size="sm" title="Excluir Foto"
      @ok="excluirFoto(fotoExclusao)" ok-title="Sim, excluir" ok-variant="danger" cancel-variant="light"
      cancel-title="Cancelar">
      Deseja realmente excluir a foto?
    </b-modal>
    <!-- Modal  Visualizar Foto -->
    <b-modal id="visualizar-foto-modal" ref="visualizarFotoModal" size="lg" title="Visualizar Foto" hide-footer>
      <div class="d-flex justify-content-center">
        <img :src="fotoVisualizada" height="600" width="100%" />
      </div>
    </b-modal>
    <!-- Modal  Uploading Fotos -->
    <b-modal id="upload-photos-modal" ref="uploadPhotosModal" title="Adicionar Fotos" hide-footer>
      <b-form-group label="Adicione 1 ou mais fotos" label-for="fotos">
        <b-form-file v-model="fotosUpload" label="Fotos" label-for="fotos" browse-text="Procurar"
          placeholder="Escolha um arquivo" drop-placeholder="Escolha um arquivo" accept=".png, .jpg, .jpeg" multiple />
        <div class="d-flex justify-content-between">
          <b-button type="button" variant="light" class="mt-1" @click="uploadPhotos">
            Cancelar</b-button>
          <b-button type="button" variant="success" class="mt-1" @click="uploadPhotos">
            Salvar</b-button>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
  import { BButton, BCol, BForm, BImg, BFormFile, BFormGroup, BFormInput, BFormRadio, BRow, BFormTextarea, BModal } from 'bootstrap-vue'
  import store from '@/store'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import vSelect from 'vue-select'
  import ModalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import { set } from 'lodash'

  export default {
    components: {
      BButton,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      vSelect,
      BFormFile,
      BFormRadio,
      BFormTextarea,
      BModal,
      ModalConfirmacao,
      BImg
    },
    props: {     
      listaUnidadeMedidas: {
        type: Array,
        required: true,
      },
      listaMarcas: {
        type: Array,
        required: true,
      },
      listaFamilias: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        itemData: null,
        fotosUpload: null,
        fotoVisualizada: null,
        fotoExclusao: null,
      }
    },
      created() {
      this.getProduto()    
    },
    computed: {
      listaFotos() {
        return this.itemData.fotos
      },    
    },

    methods: {
      visualizarFoto(foto) {
        this.fotoVisualizada = foto
        this.$refs.visualizarFotoModal.show();
      },
      showModal() {
        this.$refs.uploadPhotosModal.show();
      },
      getProduto() {
        store.dispatch('produtos/show', { id: this.$router.currentRoute.params.id })  
          .then(respo => {            
             this.itemData = respo.data.data           
          })
      },
      getProdutoUpdateFoto() {
        store.dispatch('produtos/show', { id: this.$router.currentRoute.params.id })  
          .then(respo => {            
             this.itemData.fotos = respo.data.data.fotos        
          })
      },
      editarItem() {
        store.dispatch('produtos/update', this.itemData)
          .then(respo => {
            this.$router.push({ name: 'produtos' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Produto editado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
      },
      uploadPhotos() {
        const fotosParaUpload = {
          fotos: this.fotosUpload,
          id: this.itemData.id,
        }
        store.dispatch('produtos/uploadFotos', fotosParaUpload)
          .then(respo => {
            setTimeout(() => {
              // Atualiza a lista de fotos, caso nao tenha isso a foto tende a nao aparecer pq nao deu tempo de salvar space
              this.getProdutoUpdateFoto()
            }, 500)           
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'As fotos foram adicionadas ao produto.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        this.$refs.uploadPhotosModal.hide();
      },
      modalExcluir(item) {
        this.fotoExclusao = item

        this.$root.$emit('bv::show::modal', 'excluir-foto-modal')
      },
      excluirFoto(item) {
        const foto = {
          id: item.id,
          produto_id: this.itemData.id
        }
        store.dispatch('produtos/deleteFotos', foto)
          .then(respo => {
            this.getProdutoUpdateFoto()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Foto excluída com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        this.$root.$emit('bv::hide::modal', 'modal-confirmacao-exclusao-foto')
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>