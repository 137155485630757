import axios from '@axios'

const urlModelo = '/produtos'

export default {
  namespaced: true,
  state: {
    load: false,

  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get(urlModelo, { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchUnidadeMedida() {
      return new Promise((resolve, reject) => {
        axios
          .get('/unidades-medida')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    fetchMarca() {
      return new Promise((resolve, reject) => {
        axios
          .get('/marcas?perPage=500&sortBy=nome&sortDesc=false')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    fetchFamiliaProduto() {
      return new Promise((resolve, reject) => {
        axios
          .get('/produto-familia?perPage=500&sortBy=nome&sortDesc=false')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
      },

    show(ctx, item) {

      return new Promise((resolve, reject) => {
        axios
          .get(`${urlModelo}/${item.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      Object.keys(itemData).forEach(key => {
        if (itemData[key] !== undefined && itemData[key] !== null) {
          if (typeof itemData[key] === 'object' && !Array.isArray(itemData[key])) {
            Object.keys(itemData[key]).forEach(subKey => {
              formData.append(`${key}[${subKey}]`, itemData[key][subKey]);
            });
          } else {
            formData.append(key, itemData[key]);
          }
        }
      });
      return new Promise((resolve, reject) => {
        axios
          .post(urlModelo, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
console.log(itemData)
      const formData = new FormData()
      Object.keys(itemData).forEach(key => {
        if (itemData[key] !== undefined && itemData[key] !== null) {
          if(key ==='marca' || key === 'unidade_medida' || key === 'fotos') {
            return
          }
          if (typeof itemData[key] === 'object' && !Array.isArray(itemData[key])) {
            Object.keys(itemData[key]).forEach(subKey => {
              formData.append(`${key}[${subKey}]`, itemData[key][subKey]);
            });
          } else {
            formData.append(key, itemData[key]);
          }
        }
      });
  
      formData.append('marca', itemData.marca.id || itemData.marca)
      formData.append('unidade_medida', itemData.unidade_medida.uuid || itemData.unidade_medida.id )

      formData.append('_method', 'PUT')

      return axios.post(`produtos/${itemData.id}`, formData)
        .then(response => response)
        .catch(error => reject(error))
    },

    uploadFotos(ctx, itemData) {
      const formData = new FormData()

      itemData.fotos.forEach((foto, index) => {
        formData.append('fotos[]', foto)
      })
      formData.append('_method', 'PUT')

      return axios.post(`produtos/${itemData.id}`, formData)
        .then(response => response)
        .catch(error => reject(error))
    },

    deleteFotos(ctx, itemData) {
      const formData = new FormData()
  
      formData.append('fotos_deletar[]', itemData.id)
      formData.append('_method', 'PUT')

      return axios.post(`produtos/${itemData.produto_id}`, formData)
        .then(response => response)
        .catch(error => reject(error))
    },

    deleteItem(ctx, { id }) {

      return new Promise((resolve, reject) => {
        axios
          .delete(`/produtos/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
